import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './BlogPreview.module.scss';

type BlogPreviewProps = {
  date: string;
  description: string;
  slug: string;
  thumbnail: any;
  title: string;
};

const BlogPreview = ({
  date,
  description,
  slug,
  thumbnail,
  title,
}: BlogPreviewProps) => {
  const getImage = ({ title, thumbnail }) => (
    <GatsbyImage alt={title} className={styles.image} image={thumbnail} />
  );

  return (
    <article className={styles.wrapper}>
      {thumbnail ? (
        <Link className={styles.thumbnail} to={slug}>
          {getImage({ title, thumbnail })}
        </Link>
      ) : null}
      <header>
        <h2 className={styles.header}>
          <Link to={slug} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small className={styles.date}>{date}</small>
      </header>
      <section className={styles.details}>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          itemProp="description"
        />
        <p className={styles.more}>
          <Link className={styles.moreLink} to={slug} itemProp="url">
            Read more
          </Link>
        </p>
      </section>
    </article>
  );
};

export default BlogPreview;
