import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import BlogPreview from './../BlogPreview/BlogPreview';
import Button from './../Button/Button';
import Title from './../Title/Title';

import * as styles from './BlogList.module.scss';

type BlogListProps = {
  hasSidebar: Boolean;
  moreLink?: string;
  posts: Array<any>;
  title: string;
};

const BlogList = ({ hasSidebar, moreLink, posts, title }: BlogListProps) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      {title && <Title text={title} />}

      <div
        className={classNames(
          styles.layout,
          hasSidebar ? styles.hasSidebar : null
        )}
      >
        {posts.map((post, index) => {
          const title = post.frontmatter.title || post.fields.slug;

          return (
            <div className={styles.preview} key={title}>
              <BlogPreview
                date={post.frontmatter.date}
                description={post.frontmatter.description || post.excerpt}
                slug={post.fields.slug}
                thumbnail={post.thumbnail}
                title={title}
              />
            </div>
          );
        })}
      </div>
      {moreLink ? (
        <p className={styles.moreLink}>
          <Button text="View more" url={moreLink} />
        </p>
      ) : null}
    </div>
  </section>
);

export default BlogList;
