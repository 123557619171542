import * as React from 'react';
import { Link } from 'gatsby';

import * as styles from './Title.module.scss';

type TitleProps = {
  text: string;
};

const Title = ({ text }: TitleProps) => (
  <h1 className={styles.title}>{text}</h1>
);

export default Title;
