import * as React from 'react';
import { Link, graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/PageLayout';
import SEO from '../components/seo';
import BlogContainer from '../containers/BlogContainer/BlogContainer';
import SectionLayout from '../components/SectionLayout/SectionLayout';
import SideBarDonate from '../components/SideBarDonate/SideBarDonate';
import SideBarInfo from '../components/SideBarInfo/SideBarInfo';

const News = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.news.nodes;
  const {
    donateSettings: { current, goal },
  } = data;

  return (
    <PageLayout location={location} title={siteTitle}>
      <SEO title="News" />

      <SectionLayout
        title="News"
        sidebar={
          <>
            <SideBarDonate current={current} goal={goal} />
            <SideBarInfo />
          </>
        }
      >
        <BlogContainer hasSidebar={true} posts={posts || []}></BlogContainer>
      </SectionLayout>
    </PageLayout>
  );
};

export default News;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    donateSettings: settingsYaml(section: { eq: "donate" }) {
      current
      goal
      reason
    }
    news: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { section: { eq: "news" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          description
          section
        }
      }
    }
  }
`;
