import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from './../Button/Button';

import * as styles from './SideBarDonate.module.scss';

const SideBarDonate = ({ current, goal }) => {
  const percentageComplete = (100 / goal) * current;

  const textAlign =
    percentageComplete > 85
      ? 85
      : percentageComplete < 15
      ? 15
      : percentageComplete;

  return (
    <section className={styles.wrapper}>
      <p className={styles.highlight}>
        We've raised <span className={styles.amount}>£{current}</span> so far!
      </p>
      <div className={styles.container}>
        <div className={styles.information}>
          <p className={styles.goal}>
            <strong>Goal:</strong> £{goal}
          </p>
          <p className={styles.current} style={{ top: `${100 - textAlign}%` }}>
            <strong>Current:</strong> £{current}
          </p>
        </div>
        <div className={styles.barometer}>
          <div
            className={styles.current}
            style={{ height: `${percentageComplete}%` }}
          ></div>
        </div>
      </div>
      <Button text="Donate" url="/donate" />
    </section>
  );
};

export default SideBarDonate;
