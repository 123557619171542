import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './SideBarInfo.module.scss';

const SideBarInfo = () => (
  <>
    <Link className={styles.shop} to="/">
      <StaticImage
        className={styles.image}
        layout="constrained"
        formats={['AUTO', 'WEBP', 'AVIF']}
        src="./../../images/preloved.jpg"
        width={580}
        quality={95}
        alt="Preloved Uniform Shop"
      />
    </Link>
  </>
);

export default SideBarInfo;
