import * as React from 'react';
import { Link } from 'gatsby';

import SideBar from '../SideBar/SideBar';
import Title from '../Title/Title';

import * as styles from './SectionLayout.module.scss';

const SectionLayout = ({ image, location, title, children, sidebar }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Title text={title} />
        {image ? (
          <div className={styles.imageContainer}>
            <div className={styles.image}>{image}</div>
          </div>
        ) : null}
        <div className={styles.layout}>
          <main className={styles.main}>{children}</main>
          <aside className={styles.aside}>{sidebar || <SideBar />}</aside>
        </div>
      </div>
    </div>
  );
};

export default SectionLayout;
