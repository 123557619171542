import * as React from 'react';
import { Link } from 'gatsby';

import * as styles from './Button.module.scss';

type ButtonProps = {
  text: string;
  url: string;
};

const Button = ({ text, url }: ButtonProps) => {
  const isExternal = url.indexOf('http') > -1;

  const content = (
    <>
      {text}

      <svg
        className={styles.arrow}
        width="14"
        height="14"
        viewBox="0 0 10 10"
        aria-hidden="true"
      >
        <g fillRule="evenodd">
          <path className={styles.line} d="M0 5h7"></path>
          <path className={styles.tip} d="M1 1l4 4-4 4"></path>
        </g>
      </svg>
    </>
  );

  return isExternal ? (
    <a href={url} className={styles.button} target="_blank">
      {content}
    </a>
  ) : (
    <Link to={url} className={styles.button}>
      {content}
    </Link>
  );
};

export default Button;
