import * as React from 'react';

import { getImage } from 'gatsby-plugin-image';
import BlogList from '../../components/BlogList/BlogList';

type DataProps = {
  hasSidebar: Boolean;
  posts: Array<any>;
  moreLink?: string;
  titie: string;
};

const BlogContainer: React.FC<DataProps> = ({
  hasSidebar,
  posts,
  moreLink,
  title,
}) => {
  const postList = posts.map((post: any) => ({
    ...post,
    thumbnail: post.frontmatter.thumbnail
      ? getImage(post.frontmatter.thumbnail)
      : null,
  }));

  return (
    <BlogList
      hasSidebar={hasSidebar}
      posts={postList}
      moreLink={moreLink}
      title={title}
    />
  );
};

export default BlogContainer;
